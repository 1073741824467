import { Auth } from "@aws-amplify/auth";
import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { Pagination } from "./utils/pagination";

type OmResourceType = string;

class OmDataProvider implements DataProvider<OmResourceType> {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  async getList<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    console.log("getList", resource, params);

    const sig = JSON.stringify({
      resource,
      itemsPerPage: params.pagination.perPage,
    });
    const page = params.pagination.page;
    const token = Pagination.getNextToken(sig, page);

    const url = new URL(`${location.origin}${this.path}/${resource}`);
    url.searchParams.append("itemsPerPage", params.pagination.perPage + "");
    if (token) {
      url.searchParams.append("after", token);
    }

    const { items, endCursor } = await (
      await fetch(url, {
        headers: await this.requireAuthHeaders(),
      })
    ).json();

    Pagination.saveNextToken(endCursor, sig, page);

    return {
      data: items,
      pageInfo: {
        hasNextPage: endCursor !== undefined,
        hasPreviousPage: page > 1,
      },
    };
  }

  async getOne<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    console.log("getOne", resource, params);

    let { item } = await (
      await fetch(`${this.path}/${resource}/${params.id}`, {
        headers: await this.requireAuthHeaders(),
      })
    ).json();

    return { data: item };
  }

  async getMany<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    console.log("getMany", resource, params);

    throw new Error("nyi");
  }

  getManyReference<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    console.log("getManyReference", resource, params);

    throw new Error("nyi");
  }

  update<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    console.log("update", resource, params);

    throw new Error("nyi");
  }

  updateMany<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: UpdateManyParams
  ): Promise<UpdateManyResult<RecordType>> {
    console.log("updateMany", resource, params);

    throw new Error("nyi");
  }

  async create<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: CreateParams
  ): Promise<CreateResult<RecordType>> {
    console.log("create", resource, params);

    const data = params.data;
    removeEmptyKinds(data);

    const r = await (
      await fetch(`${this.path}/${resource}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...(await this.requireAuthHeaders()),
        },
        body: JSON.stringify(data),
      })
    ).json();

    return { data: r.item };
  }

  async delete<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: DeleteParams<RecordType>
  ): Promise<DeleteResult<RecordType>> {
    console.log("delete", resource, params);

    await fetch(`${this.path}/${resource}/${params.id}`, {
      headers: await this.requireAuthHeaders(),
      method: "delete",
    });

    const data = { id: params.id } as any;
    return { data };
  }

  async deleteMany<RecordType extends RaRecord = any>(
    resource: OmResourceType,
    params: DeleteManyParams<RecordType>
  ): Promise<DeleteManyResult<RecordType>> {
    console.log("deleteMany", resource, params);

    for (const id of params.ids) {
      await this.delete(resource, { id });
    }

    return { data: params.ids };
  }

  async requireAuthHeaders(): Promise<{ authorization: string }> {
    const authToken = await this.requireAuthToken();

    return { authorization: `Bearer ${authToken}` };
  }

  async requireAuthToken(): Promise<string> {
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
  }
}

export function omDataProvider(path = "/api"): DataProvider {
  return new OmDataProvider(path);
}

function removeEmptyKinds(d: any): void {
  if (typeof d === "object") {
    for (const k in d) {
      if (typeof d[k] === "object") {
        if (d[k]?.kind === "") {
          delete d[k];
        } else {
          removeEmptyKinds(d[k]);
        }
      }
    }
  }
}
