import { ReactElement } from "react";
import {
  ArrayInput,
  Create,
  CreateProps,
  Datagrid,
  DateInput,
  DateTimeInput,
  Edit,
  EditProps,
  FormDataConsumer,
  FunctionField,
  List,
  NumberInput,
  regex,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TextInputProps,
} from "react-admin";
import {
  Discount,
  DiscountAdjustment,
  DiscountValue,
  LineItemCondition,
} from "../rpc";
import { LineItemFilter } from "../rpc";
import { Typography } from "@mui/material";

export const DiscountList = () => (
  <List>
    <Datagrid rowClick="edit">
      <FunctionField label="Discount" render={DiscountTitle} />
      <FunctionField label="Conditions" render={DiscountConditionField} />
      <FunctionField
        label="Adjustment"
        render={(d: Discount) => <DiscountAdjustmentField {...d.adjustment} />}
      />
      <FunctionField
        label="Sequence"
        render={(d: Discount) => printSequence(d.sequence)}
      />
      <FunctionField label="# Used" render={(d: Discount) => printUsed(d)} />
    </Datagrid>
  </List>
);

function DiscountTitle(i: Discount): ReactElement {
  return (
    <div>
      <p>{i.code}</p>
      <p>{i.title}</p>
    </div>
  );
}

function DiscountConditionField(d: Discount): ReactElement {
  let subtotalLi;
  if (d.minCurrentSubtotal) {
    subtotalLi = <li>`Min Purchase P${d.minCurrentSubtotal}`</li>;
  }

  let lineItemConditionLis =
    d.lineItemConditions &&
    d.lineItemConditions.map((c, ndx) => (
      <LineItemConditionLi key={ndx} {...c} />
    ));

  if (!subtotalLi && !lineItemConditionLis) {
    return <p>None</p>;
  }

  return (
    <ul>
      {subtotalLi}
      {lineItemConditionLis}
    </ul>
  );
}

function LineItemConditionLi(c: LineItemCondition): ReactElement {
  const filterText = printLineItemFilter(c.filter);

  const minCurrentTotalLi = c.minCurrentTotal && (
    <li>Min purchase P{c.minCurrentTotal}</li>
  );
  const minQuantitylLi = c.minQuantity && <li>Min qty {c.minQuantity}</li>;

  return (
    <li>
      <p>{filterText}</p>
      <ul>
        {minCurrentTotalLi}
        {minQuantitylLi}
      </ul>
    </li>
  );
}

function DiscountAdjustmentField(a: DiscountAdjustment): ReactElement {
  const valueText = printDiscountValue(a.value);

  let description;

  switch (a.kind) {
    case "ORDER":
      description = "Order";
      break;
    case "SHIPPING":
      description = "Shipping";
      break;
    case "LINE_ITEM":
      description = `${printLineItemFilter(a.filter)}`;
      break;
  }

  return (
    <div>
      <p>{valueText}</p>
      <p>{description}</p>
    </div>
  );
}

function printLineItemFilter(f: LineItemFilter | undefined): string {
  switch (f?.kind) {
    case "PRODUCT_IDS":
      return `Product Ids - ${f.ids.join(", ")}`;
    case "PRODUCT_TAGS":
      return `Product Tags - ${f.tags.join(", ")}`;
    default:
      return "All Line Items";
  }
}

function printDiscountValue(v: DiscountValue): string {
  return v.maxFixed
    ? v.maxPct >= 100
      ? `P${v.maxFixed} off`
      : `${v.maxPct}% off, ${v.maxFixed} capped`
    : `${v.maxPct}% off`;
}

function printSequence(seq: number): ReactElement {
  return (
    <div>
      <p>{seq}</p>
      <p style={{ fontSize: "10px" }}>{stageForSequence(seq)}</p>
    </div>
  );
}

function printUsed(d: Discount): string {
  let content = d.usageCount ?? 0;
  let suffix = d.usageMax !== undefined ? "/" + d.usageMax : "";

  return content + suffix;
}

enum DiscountStage {
  PRE_INFO_FETCH = "PRE_INFO_FETCH",
  PRE_FULFILLMENT_PLAN = "PRE_FULFILLMENT_PLAN",
  PRE_SHIPPING_QUOTATION = "PRE_SHIPPING_QUOTATION",
  PRE_ORDER_CREATION = "PRE_ORDER_CREATION",
}

function stageForSequence(seq: number): DiscountStage {
  if (seq <= 20) {
    return DiscountStage.PRE_INFO_FETCH;
  } else if (seq <= 40) {
    return DiscountStage.PRE_FULFILLMENT_PLAN;
  } else if (seq <= 60) {
    return DiscountStage.PRE_SHIPPING_QUOTATION;
  } else {
    return DiscountStage.PRE_ORDER_CREATION;
  }
}

export const DiscountEdit = (props: EditProps<Discount>) => (
  <Edit {...props}>
    <DiscountForm codeEditable={false} />
  </Edit>
);

export function DiscountCreate(props: CreateProps<Discount>): ReactElement {
  return (
    <Create {...props}>
      <DiscountForm codeEditable={true} />
    </Create>
  );
}

type DiscountFormProps = {
  codeEditable: boolean;
};

function DiscountForm(p: DiscountFormProps): ReactElement {
  return (
    <SimpleForm sanitizeEmptyValues>
      <TextInput
        source="code"
        format={formatCode}
        required={p.codeEditable}
        disabled={!p.codeEditable}
      />
      <TextInput source="title" fullWidth required />
      <SelectInput
        source="sequence"
        label="Discount Sequence"
        choices={sequenceChoices}
        defaultValue={80}
      />
      <DateTimeInput source="validityStart" />
      <DateTimeInput source="validityEnd" />
      <Typography variant="h6">Conditions</Typography>
      <ArrayInput source="lineItemConditions">
        <SimpleFormIterator>
          <SelectInput
            source="filter.kind"
            label="Line Item Filter Kind"
            choices={lineItemFilterChoices}
          />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              const source = getSource?.("filter.ids") ?? "";
              return (
                scopedFormData?.filter.kind === "PRODUCT_IDS" && (
                  <ArrayInput source={source}>
                    <SimpleFormIterator>
                      <TextInput source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              const source = getSource?.("filter.tags") ?? "";
              return (
                scopedFormData?.filter.kind === "PRODUCT_TAGS" && (
                  <ArrayInput source={source}>
                    <SimpleFormIterator>
                      <TextInput source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              );
            }}
          </FormDataConsumer>
          <MoneyInput source="minCurrentTotal" />
          <NumberInput source="minQuantity" defaultValue={0} />
        </SimpleFormIterator>
      </ArrayInput>

      <SelectInput
        source="customerFilter.kind"
        label="Customer"
        choices={customerFilterChoices}
      />
      <FormDataConsumer>
        {({ formData }) => {
          return (
            formData?.customerFilter?.kind === "CUSTOMER_IDS" && (
              <ArrayInput source={"customerFilter.ids"} label="Customer IDs">
                <SimpleFormIterator>
                  <TextInput source="" />
                </SimpleFormIterator>
              </ArrayInput>
            )
          );
        }}
      </FormDataConsumer>
      <MoneyInput source="minSubtotal" />
      {/*Adjustment*/}
      <Typography variant="h6">Adjustment</Typography>
      <SelectInput
        source="adjustment.kind"
        choices={adjustmentChoices}
        required
        defaultValue="ORDER"
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.adjustment?.kind === "LINE_ITEM" && (
            <>
              <SelectInput
                source="adjustment.filter.kind"
                label="Line Item Filter Kind"
                choices={lineItemFilterChoices}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.adjustment?.filter?.kind === "PRODUCT_IDS" && (
                    <ArrayInput
                      source="adjustment.filter.ids"
                      label="Product Ids"
                      {...rest}
                    >
                      <SimpleFormIterator>
                        <TextInput source="" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.adjustment?.filter?.kind === "PRODUCT_TAGS" && (
                    <ArrayInput
                      source="adjustment.filter.tags"
                      label="Product Tags"
                      {...rest}
                    >
                      <SimpleFormIterator>
                        <TextInput source="" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
            </>
          )
        }
      </FormDataConsumer>
      <NumberInput
        source="adjustment.value.maxPct"
        label="Max Percentage (% value)"
        min={0}
        max={100}
        step={1}
        defaultValue={100}
      />
      <MoneyInput
        source="adjustment.value.maxFixed"
        label="Max Fixed (Peso value)"
      />
      <NumberInput source="usageMax" />
      <NumberInput source="usageMaxPerCustomer" />
    </SimpleForm>
  );
}

function MoneyInput(p: TextInputProps): ReactElement {
  const validateMoney = regex(
    /^\d*(\.(\d{1,2}))?$/,
    "Must be a valid monetary value"
  );

  return <TextInput placeholder="0.00" validate={validateMoney} {...p} />;
}

function formatCode(s: string): string {
  return s.toUpperCase().replace(/[^0-9A-Z]/g, "");
}

const adjustmentChoices = [
  { id: "ORDER", name: "Order" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "LINE_ITEM", name: "Line Item" },
];

const lineItemFilterChoices = [
  { id: "PRODUCT_IDS", name: "Product Ids" },
  { id: "PRODUCT_TAGS", name: "Product Tags" },
];

const customerFilterChoices = [{ id: "CUSTOMER_IDS", name: "Customer Ids" }];

const sequenceChoices = [
  { id: 30, name: "30 - Pre Fulfillment Plan" },
  { id: 40, name: "40 - Pre Fulfillment Plan" },
  { id: 50, name: "50 - Pre Shipping Quotation" },
  { id: 60, name: "60 - Pre Shipping Quotation" },
  { id: 70, name: "70 - Pre Order Creation" },
  { id: 75, name: "75 - Pre Order Creation" },
  { id: 80, name: "80 - Pre Order Creation" },
  { id: 85, name: "85 - Pre Order Creation" },
  { id: 90, name: "90 - Pre Order Creation" },
  { id: 95, name: "95 - Pre Order Creation" },
  { id: 100, name: "100 - Pre Order Creation" },
];
