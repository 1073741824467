import { Auth } from "@aws-amplify/auth";
import React, { ReactElement, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { BrowserRouter } from "react-router-dom";
import { cognitoAuthProvider } from "./cognito-auth-provider";
import {
  DiscountCreate,
  DiscountEdit,
  DiscountList,
} from "./entities/discount";
import { omDataProvider } from "./om-data-provider";

const dataProvider = omDataProvider();

let authOptions;
switch (location.hostname) {
  case "admin.om.shopsm.com":
    authOptions = {
      userPoolId: "ap-southeast-1_ZD96HphrR",
      clientId: "hl1i4m63puumbqbpir0j2kdah",
      hostedUiDomain: "om-admin-prod.auth.ap-southeast-1.amazoncognito.com",
    };
    break;
  case "admin.dev.om.sh0psm.xyz":
    authOptions = {
      userPoolId: "ap-southeast-1_yZZqF08Bw",
      clientId: "qv4iotol16go09tnqs5r13ul",
      hostedUiDomain: "om-admin-dev.auth.ap-southeast-1.amazoncognito.com",
    };
    break;
  default: // set uat as default
    authOptions = {
      userPoolId: "ap-southeast-1_AqS9pAa2Q",
      clientId: "3aga7ueub2lar3e79j2ao1ucfa",
      hostedUiDomain: "om-admin-uat.auth.ap-southeast-1.amazoncognito.com",
    };
    break;
}
console.log(authOptions);

const authProvider = cognitoAuthProvider(authOptions);

export function App(): ReactElement {
  return (
    <BrowserRouter>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={CognitoLoginPage}
      >
        <Resource
          name="discounts"
          list={DiscountList}
          edit={DiscountEdit}
          create={DiscountCreate}
        />
      </Admin>
    </BrowserRouter>
  );
}

function CognitoLoginPage(): ReactElement {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => (window.location.href = "/"))
      .catch((e) => {
        // sigh. errors are strings
        if (e === "The user is not authenticated") {
          Auth.signOut().then(() => Auth.federatedSignIn());
        } else {
          console.error("Error getting authenticated user", e);
        }
      });
  }, []);

  return <div>Redirecting to cognito...</div>;
}

export default App;
